<template>
  <div class="user-sa-list">
    <UiBanner fluid :title="$t('userSA.list.name')">
      <template v-slot:actions>
        <div class="tw-flex tw-items-center">
          <v-text-field
            v-model="search"
            :value="search"
            :prepend-inner-icon="`${icons.mdiMagnify}`"
            :label="$t('userSA.list.search.label')"
            hide-details
            outlined
            solo
            flat
            dense
            clearable
            @input="searchInput"
          />

          <ui-list-filters @validate="getUserFiltered">
            <template v-slot:content>
              <v-list>
                <v-list-item>
                  <ui-block class="tw-w-full" id="roleLabel" :label="$t('userSA.list.filters.role.label')">
                    <template v-slot:body>
                      <v-autocomplete
                        id="role"
                        v-model="filters.role"
                        solo
                        flat
                        outlined
                        dense
                        clearable
                        item-value="value"
                        item-text="name"
                        :items="roles"
                        :placeholder="$t('userSA.list.filters.role.placeholder')"
                      />
                    </template>
                  </ui-block>
                </v-list-item>
                <v-list-item>
                  <ui-block class="tw-w-full" id="statusLabel" :label="$t('userSA.list.filters.status.label')">
                    <template v-slot:body>
                      <v-autocomplete
                        id="status"
                        v-model="filters.status"
                        solo
                        flat
                        outlined
                        dense
                        clearable
                        item-value="value"
                        item-text="label"
                        :items="userStatus"
                        :placeholder="$t('userSA.list.filters.status.placeholder')"
                      >
                        <template v-slot:selection="data">
                          <v-chip class="tw-my-2" outlined :color="data.item.color">
                            <v-icon left>
                              {{ data.item.icon }}
                            </v-icon>
                            {{ data.item.label }}
                          </v-chip>
                        </template>
                        <template v-slot:item="data">
                          <v-chip outlined :color="data.item.color">
                            <v-icon left>
                              {{ data.item.icon }}
                            </v-icon>
                            {{ data.item.label }}
                          </v-chip>
                        </template>
                      </v-autocomplete>
                    </template>
                  </ui-block>
                </v-list-item>
                <v-list-item>
                  <ui-block class="tw-w-full" id="clientLabel" :label="$t('userSA.list.filters.client.label')">
                    <template v-slot:body>
                      <v-autocomplete
                        id="client"
                        v-model="filters.client"
                        solo
                        flat
                        outlined
                        dense
                        clearable
                        item-value="uuid"
                        item-text="name"
                        :items="clients"
                        :placeholder="$t('userSA.list.filters.client.placeholder')"
                      />
                    </template>
                  </ui-block>
                </v-list-item>
              </v-list>
            </template>
          </ui-list-filters>

          <v-menu offset-y>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" class="tw-ml-4 tw-rounded-md" color="primary">
                {{ $t('userSA.list.search.create.button') }}
              </v-btn>
            </template>
            <v-list>
              <v-list-item-group>
                <v-list-item target="_blank" :href="`${URLS.BO_V3}/clients/id/settings/list/manage`" class="tw-text-sm">
                  <v-icon left class="tw-mr-4">{{ icons.mdiAccount }}</v-icon>
                  {{ $t('userSA.list.search.create.default') }}
                </v-list-item>
                <v-list-item :to="{ name: 'UserSAManage' }" class="tw-text-sm">
                  <v-icon left class="tw-mr-4">{{ icons.mdiShieldCrown }}</v-icon>
                  {{ $t('userSA.list.search.create.sa') }}
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-menu>
        </div>
      </template>
    </UiBanner>

    <UiContainer no-padding>
      <div class="user-sa-list__table-container">
        <v-data-table
          style="cursor: pointer"
          class="user-sa-list__table-container__table"
          height="100%"
          item-key="id"
          fixed-header
          :loading="refreshing"
          :headers="headers"
          :items="users"
          :options.sync="options"
          :search="search"
          :footer-props="{ itemsPerPageOptions: [25, 50, 100] }"
          @click:row="goToUserEdit"
        >
          <template v-slot:[`item.email`]="{ item }">
            <div class="tw-my-2 tw-flex tw-flex-col tw-gap-2">
              <v-chip class="tw-w-min" color="#f7f7f8" x-small @click.stop="copyToClipboard(item.uuid)">
                {{ item.uuid }}
              </v-chip>
              <v-chip class="tw-w-min" color="primary" small @click.stop="copyToClipboard(item.email)">
                {{ item.email }}
              </v-chip>
            </div>
          </template>
          <template v-slot:[`item.client_name`]="{ item }">
            <div v-for="(name, index) in item.client_name.split(',')" :key="`client name ${index}`">
              {{ name }}
            </div>
          </template>
          <template v-slot:[`item.defaultLocaleIsoCode`]="{ item }">
            <div v-if="item.defaultLocaleIsoCode" class="tw-flex tw-flex-row tw-items-center tw-gap-2">
              <div v-if="getSvgBy('lang', item.defaultLocaleIsoCode)">
                <v-img width="25" :src="getSvgBy('lang', item.defaultLocaleIsoCode)" />
              </div>
            </div>
          </template>
          <template v-slot:[`item.status`]="{ item }">
            <v-chip outlined v-if="item.status === true" color="success">
              <v-icon left>
                {{ icons.mdiCheckboxMarkedCircleOutline }}
              </v-icon>
              {{ $t('userSA.list.active') }}
            </v-chip>
            <v-chip outlined v-else color="error">
              <v-icon left>
                {{ icons.mdiCloseCircleOutline }}
              </v-icon>
              {{ $t('userSA.list.inactive') }}
            </v-chip>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on">
                  {{ icons.mdiDotsVertical }}
                </v-icon>
              </template>
              <v-list>
                <v-list-item-group>
                  <v-list-item class="tw-text-sm" @click="updateUserStatus(item, false)" v-if="item.status === true">
                    <v-icon left class="tw-mr-4">{{ icons.mdiAccountOff }}</v-icon>
                    {{ $t('userSA.interaction.disabled') }}
                  </v-list-item>
                  <v-list-item class="tw-text-sm" @click="updateUserStatus(item, true)" v-else>
                    <v-icon left class="tw-mr-4">{{ icons.mdiAccountCheck }}</v-icon>
                    {{ $t('userSA.interaction.reactivate') }}
                  </v-list-item>
                  <v-list-item class="tw-text-sm" @click="sendUserInvitation(item.email)">
                    <v-icon left class="tw-mr-4">{{ icons.mdiEmail }}</v-icon>
                    {{ $t('userSA.interaction.invitation') }}
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-menu>
          </template>
        </v-data-table>
      </div>
    </UiContainer>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import {
  mdiMagnify,
  mdiCheckboxMarkedCircleOutline,
  mdiCloseCircleOutline,
  mdiDotsVertical,
  mdiAccountOff,
  mdiAccountCheck,
  mdiAccount,
  mdiEmail,
  mdiShieldCrown,
} from '@mdi/js'

import UiBlock from '@/components/UI/Block.vue'
import UiBanner from '@/components/UI/Banner.vue'
import UiContainer from '@/components/UI/Container.vue'
import UiListFilters from '@/components/UI/List/Filters.vue'

import googleRecaptchaMixin from '@/mixins/google-recaptcha.mixin'
import exceptionManage from '@/mixins/exceptionManage.mixin'

import { debounce } from '@/utils/debounce.util'
import { getSvgBy } from '@/utils/flag.util'
import { URLS } from '@/config/urls.config'
import { copyToClipboard } from '@/utils/utilities.util'

export default {
  name: 'UserSAList',
  components: {
    UiBanner,
    UiContainer,
    UiBlock,
    UiListFilters,
  },
  mixins: [googleRecaptchaMixin, exceptionManage],
  data: () => ({
    URLS,
    getSvgBy,
    icons: {
      mdiMagnify,
      mdiCheckboxMarkedCircleOutline,
      mdiCloseCircleOutline,
      mdiDotsVertical,
      mdiAccountOff,
      mdiAccountCheck,
      mdiEmail,
      mdiShieldCrown,
      mdiAccount,
    },
    refreshing: false,
    search: '',
    options: {
      itemsPerPage: 25,
    },
    filters: {
      role: null,
      status: null,
      client: null,
    },
  }),
  async created() {
    this.refreshing = true
    await Promise.all([
      this.initRecaptcha(),
      this.getRoles(),
      this.getClientsCore({ options: { size: 1000 } }),
      this.getUsers({
        options: { size: 3000 },
      }),
    ])
    this.refreshing = false
  },
  computed: {
    ...mapState({
      users: state => state.users.users,
      roles: state => state.users.roles,
      clients: state => state.client.clients,
    }),
    userStatus() {
      return [
        {
          value: 1,
          icon: this.icons.mdiCheckboxMarkedCircleOutline,
          label: this.$t('userSA.list.active'),
          color: 'success',
        },
        {
          value: 0,
          icon: this.icons.mdiCloseCircleOutline,
          label: this.$t('userSA.list.inactive'),
          color: 'error',
        },
      ]
    },
    headers() {
      return [
        {
          text: this.$t('userSA.list.headers.emailUuid'),
          value: 'email',
          sortable: true,
        },
        {
          text: this.$t('userSA.list.headers.defaultLocaleIsoCode'),
          value: 'defaultLocaleIsoCode',
          sortable: true,
        },
        {
          text: this.$t('userSA.list.headers.role'),
          value: 'role',
          sortable: true,
        },
        {
          text: this.$t('userSA.list.headers.status'),
          value: 'status',
          sortable: true,
        },
        {
          text: this.$t('userSA.list.headers.client'),
          value: 'client_name',
          sortable: true,
        },
        {
          text: this.$t('userSA.list.headers.actions'),
          value: 'actions',
          sortable: false,
        },
      ]
    },
  },
  methods: {
    ...mapActions({
      getUsers: 'users/getUsers',
      postUserInvitation: 'users/postUserInvitation',
      patchUser: 'users/patchUser',
      getRoles: 'users/getRoles',

      getClientsCore: 'client/getClientsCore',
    }),
    copyToClipboard(string) {
      this.tryManageError(() => {
        copyToClipboard(string)
      }, this.$t('notification.success.copy'))
    },
    async updateUserStatus(user, status) {
      this.refreshing = true
      await this.tryManageError(async () => {
        await this.patchUser({ params: { id: user.uuid }, body: { status } })
        await this.getUsers({ options: { size: 3000 } })
      }, this.$t('notification.update.user', { name: user.lastName }))
      this.refreshing = false
    },
    async sendUserInvitation(email) {
      this.refreshing = true
      await this.tryManageError(async () => {
        const token = await this.$recaptcha('forgetPassword')
        await this.postUserInvitation({ email, token })
      }, this.$t('notification.success.invitationUser'))
      this.refreshing = false
    },
    goToUserEdit(user) {
      this.$router.push({ name: 'UserSAEdit', params: { id: user.uuid } })
    },
    getRoleName(role) {
      return this.roles.find(currentRole => currentRole.value === role).name
    },
    async getUserFiltered() {
      this.refreshing = true
      try {
        const { status, client, role } = this.filters
        const query = [
          ...(client ? [{ key: 'clients.uuid', value: client }] : []),
          ...(status !== null ? [{ key: 'status', value: status }] : []),
          ...(role ? [{ key: 'role', value: this.getRoleName(role) }] : []),
        ]
        await this.getUsers({ query, options: { size: 3000 } })
      } catch (error) {
        // error
      }
      this.refreshing = false
    },
    searchInput: debounce(function () {
      if (this.options.page !== 1) {
        this.options.page = 1
      }
    }, 300),
  },
}
</script>

<style lang="scss" scoped>
.user-sa-list {
  &__table-container {
    @apply tw-absolute tw-top-0 tw-right-0 tw-bottom-0 tw-left-0;

    &__table {
      @apply tw-h-full tw-flex tw-flex-col;
    }
  }
}
</style>
