import { mapActions } from 'vuex'

const exceptionManage = {
  methods: {
    ...mapActions({
      setAlert: 'backoffice/setAlert',
    }),
    async tryManageError(cb, successMessage, errorMessage = this.$t('notification.error.default')) {
      try {
        await cb()
        if (successMessage) {
          this.setAlert({
            color: 'success',
            text: successMessage,
          })
        }
      } catch (error) {
        this.setAlert({
          color: 'error',
          text: errorMessage,
        })
      }
    },
  },
}

export default exceptionManage
